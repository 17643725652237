import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { commonSeoData, getSeoParams, pageSchema } from "./seoParams";

const settings = require("../../../settings.json");

const SEO = ({ link, name }) => {
  // SEO Data
  let commonSchema = commonSeoData(link);
  let seoParams = getSeoParams(link);

  let canonicalUrl = settings.homeUrl + link;
  let rel = "canonical";

  // canonicalUrl = settings.homeUrl + link;

  // if (link.includes("amp")) {
  //   canonicalUrl = settings.homeUrl + link;
  // } else {
  //   canonicalUrl = settings.homeUrl + "/amp" + link;
  //   rel = "amphtml";
  // }

  const pageSchemaMarkup = pageSchema(link);
  let schema = [];

  if (pageSchemaMarkup) {
    schema.push({
      type: "application/ld+json",
      innerHTML: JSON.stringify(pageSchemaMarkup)
    });
  }

  schema.push(
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify(commonSchema[0]),
      id: "pageschema"
    },
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify(commonSchema[1]),
      id: "organization-schema"
    }
  );

  return (
    <Helmet
      htmlAttributes={{
        defer: false,
        lang: "en"
      }}
      title={seoParams.name}
      link={[
        { rel: rel, href: canonicalUrl },
        {
          rel: "icon",
          href:
            "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/favicon_gradient.png"
        }
      ]}
      meta={[
        { name: "title", content: seoParams.name },
        { name: "description", content: seoParams.description },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        },
        { name: "application-name", content: "Piggy" },
        { name: "author", content: "Piggy" },
        { name: "publisher", content: "Piggy" },
        { name: "keywords", content: seoParams.keywords },
        { name: "theme-color", content: "#fff" },

        // Facebook tags
        { property: "og:title", content: seoParams.name },
        { property: "og:locale", content: "en_US" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: canonicalUrl },
        { property: "og:site_name", content: "Piggy" },
        { property: "og:description", content: seoParams.description },
        {
          property: "og:image",
          content:
            "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/meta-piggy-logo.png"
        },

        // Twitter tags
        { name: "twitter:title", content: seoParams.name },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:description", content: seoParams.description },
        { name: "twitter:url", content: canonicalUrl },
        { name: "twitter:site", content: "@Piggy_co_in" },
        { name: "twitter:creator", content: "@Piggy_co_in" },
        {
          name: "twitter:image",
          content:
            "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/meta-piggy-logo.png"
        },

        // schema.org
        { itemprop: "name", content: seoParams.name },
        { itemprop: "description", content: seoParams.description },
        {
          itemprop: "image",
          content:
            "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/meta-piggy-logo.png"
        }
      ]}
      script={schema}
    />
  );
};

export default SEO;
