function toTitleCase(str) {
  if (str !== undefined) {
    return str
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  }
  return str;
}

function convertUpperCase(str) {
  const res = str.replace(
    /Sbi|Icici|Lnt|Hdfc|Idfc|Tata|Idbi|Ppfas|Lic|Uti|Hsbc|Dsp|Dhfl|Pgim|Bnp|Boi|Axa/gi,
    function (x) {
      return x.toUpperCase();
    }
  );

  return res;
}

function getLastSegment(path) {
  const lastSlashRemoved =
    path.substr(path.length - 1) === "/" ? path.slice(0, -1) : path;
  const lastSegment = lastSlashRemoved.substr(
    lastSlashRemoved.lastIndexOf("/") + 1
  );
  return lastSegment;
}

export function getSeoParams(path = "") {
  let name =
    "Invest in Mutual Funds - Buy Direct Plans | Start SIP | Track EPF";
  let description =
    "Invest in Mutual Funds and SIP for free. Sign up and you can manage MF Portfolio and track the EPF account as well on our zero commission platform. You can get recommendations on best performing direct mutual funds through our financial advisory service.";
  let keywords =
    "Piggy, SIP investment, Systematic Investment Plan, Simple saving, wealth growth, Valuevest Services, Mutual Fund, Mutual Fund Investment India, Best Funds to Buy, Direct Funds, Investing in Mutual Funds, Top mutual funds, Best performing mutual funds, The Complete Investment Guide for Mutual Funds, NAVs, Return Calculator, Compare Funds, Indian Mutual Funds, Best funds, NAV of Mutual Funds, Equities, Share Trading, Robo Advisors India, Financial Advisory, Investments, savings , EPF, mobile, Android, Free Investment Platform, Mutual Fund Sahi hai";

  if (path.match("about") != null) {
    name = "About Us | Piggy - Free Direct Mutual Funds Investment Platform";
    description =
      "Piggy is a next generation mutual fund investment app that simplifies investments for you. It is an initiative started by a team of Ex-investment bankers and tech professionals to develop a product that makes investing easier and seamless for the regular investors.";
  }
  if (path.match("mutual-funds/(amc|category)") !== null) {
    const lastSegment = getLastSegment(path);

    let keyword = "";
    if (lastSegment) {
      keyword = toTitleCase(lastSegment);
      keyword = keyword.replace(/-/g, " ");
      keyword = convertUpperCase(keyword);
    }

    keywords = keywords + ", " + keyword;
    name = keyword + " Mutual Funds | Piggy - Free Investment Platform";
    description =
      keyword +
      " mutual funds: Get information on " +
      keyword +
      " mutual funds, minimum investment value, NAV, returns," +
      " best performing mutual fund schemes in a fund category and start investing the money.";

    if (path.match("amc") !== null) {
      let subKeyword = keyword.split(" ")[0];

      name =
        subKeyword +
        " Mutual Fund - Performance, NAV, " +
        subKeyword +
        "MF Rating and Returns | Piggy";
      description =
        "Get the Latest Information on " +
        subKeyword +
        " Mutual Funds, NAV, Returns and Ratings of best " +
        subKeyword +
        " schemes. Compare " +
        subKeyword +
        " MF schemes and start investing in " +
        subKeyword +
        " Fund online.";

      if (keyword == "Amc") {
        name = "List of Mutual Funds AMCs | Piggy - Free Investment Platform";
        description =
          "List of all Mutual Funds AMCs available on Piggy - amc details, RTA, aum, best performing funds, category wise funds of Mutual Funds AMCs.";
      }
    }
  } else if (path.match("mutual-funds-sahi-hai") != null) {
    name =
      "Mutual Funds Sahi Hai | Start Investing with Piggy - Free Direct Mutual Funds Investment Platform";
    description =
      "Piggy is a next generation mutual fund investment app that simplifies investments for you. It is an initiative to develop a product that makes investing easier and seamless for the all investors because MutualFundssahihai and Investing ke liye Piggy sahi hai.";
    keywords =
      keywords +
      "Mutual funds are right, MutualFundssahihai, Mutual Funds Sahi Hai, Build wealth with Piggy, Piggy for Mutual funds investments.";
  } else if (path.match("best-mutual-funds") !== null) {
    name = "Best Performing Mutual Funds | Piggy - Free Investment Platform";
    description =
      "Best performing Mutual Funds selected based on certain parameters that are both Qualitative and Quantitive in nature.";
    keywords =
      keywords +
      ", Best Mutual Funds, Top Mutual Funds, best performing funds, Qualitative methods, Quantitive analysis";
  } else if (path.match("mutual-funds") !== null) {
    if (path.match("direct") !== null) {
      const lastSegment = getLastSegment(path);

      name = lastSegment.replace(/-/g, " ");
      name = convertUpperCase(name) + " - Direct Plan";

      description =
        "Analysis on " +
        name +
        " like returns, latest NAV, historical NAV, SIP performance, tax implications, expense ratio, suitability, risk, ratings, asset allocation, AMC, objective and comparison with other funds.";
      keywords = keywords + ", Direct Plan, " + name;
    } else if (path.match("mutual-funds")) {
      name = "Mutual Funds: Basics of Mutual Funds, Types of Mutual Funds";
      description =
        "Mutual Funds: Piggy provides simple & complete guide to mutual fund investment, benefits of investing in mutual funds";
    } else {
      name = "Explore Mutual Funds | Piggy - Free Investment Platform";
      description =
        "Explore the direct mutual funds based on the different parameters like amc, fund category, minimum investment, mutual fund scheme, returns and invest the money.";
    }
  } else if (path.match("top-mutual-funds") !== null) {
    name = "Top Mutual Funds | Piggy - Free Investment Platform";
    description =
      "Best performing Mutual Funds selected based on certain parameters that are both Qualitative and Quantitive in nature.";
    keywords =
      keywords +
      ", Top mutual funds, best performing funds, Qualitative methods, Quantitive analysis";
  } else if (path.match("calculators") !== null) {
    name = "Financial Calculators - Piggy - Invest in Mutual Funds";
    description =
      "To ensure the right banking and investment decisions, use our calculators to find out what your loan EMI will be, how much you will earn and save from your deposits or your ideal life insurance cover amount, based on your assets and liabilities.";
    keywords =
      keywords +
      ", financial calculators, calculate earnings, EMI, FD interest, returns, SIP installments, reverse SIP, step up SIP, RD installment, lumpsum, returns, Piggy Calculators";

    if (path.match("fixed-deposit-calculator") !== null) {
      name = "Fixed Deposit Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use the Fixed Deposit (FD) Calculator to calculate the Maturiy Amount of your investment and the Interest Earn on your FD through compounding interest.";
      keywords =
        keywords + ", FD calculator, Fixed Deposit Calculator, Fixed Deposit";
    }

    if (path.match("mutual-fund-returns-calculator") !== null) {
      name =
        "Mutual Fund Returns (Lumpsum) Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use our Mutual Fund Returns Calculator to calculate the return of your investment at the time of maturity. The Calculator takes into consideration the investment duration and expected returns for the valuation of your investment.";
      keywords =
        keywords + ", Lumpsum calculator, Mutual Fund Returns Calculator";
    }

    if (path.match("recurring-deposit-calculator") !== null) {
      name = "Recurring Deposit Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use our Recurring Deposit (RD) Calculator to calculate the Maturiy Amount of your investment and the Interest Earn on your investments. Calculate Now!";
      keywords = keywords + ", Recurring Deposit calculator, RD calculator";
    }

    if (path.match("sip-calculator") !== null) {
      name = "SIP Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use our SIP Calculator to calculate maturity amount and total returns on your investments. Find out how much you should invest in SIP to meet your goals by providing SIP Amount, Duration, and Expected Returns.";
      keywords = keywords + ", SIP calculator, SIP returns";
    }

    if (path.match("reverse-sip-calculator") !== null) {
      name = "Reverse SIP Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Suppose you want to have Rs. 25 lakhs in 5 years. How much should you save and invest using SIP on a monthly basis from now until then? Use Reverse SIP calculator to see how much money will you need to save today to meet your future needs.";
      keywords = keywords + ", Reverse SIP calculator, reverse sip amount";
    }

    if (path.match("step-up-sip-calculator") !== null) {
      name = "Step Up SIP Calculator - Piggy - Invest in Mutual Funds";
      description =
        "With Step-up SIP you can start investing an initial amount and then gradually increase it till it reaches the final amount e.g., if your initial investment amount is 1000 per month and you specified a step-up of 10%, it will become 1100 in the second year, 1210 in the third year and so on.";
      keywords =
        keywords +
        ", Step Up SIP calculator, Step Up SIP rate, Step Up SIP amount";
    }
    if (path.match("nps-calculator") !== null) {
      name =
        "National Pension Scheme (NPS) Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use the National Pension Scheme (NPS) Calculator to calculate your Monthly Pension at the time of retirement. Find out Annuity Amount and retirement fund you will receive by providing your Monthly Contributions and the Expected Rate of Interest.";
      keywords =
        keywords + "National Pension Scheme Calculator, NPS Calculator, NPS";
    }
    if (path.match("sukanya-samriddhi-yojana-calculator") !== null) {
      name =
        "Sukanya Samriddhi Yojana Calculator - Piggy - Invest in Mutual Funds";
      description =
        "Use the Sukanya Samriddhi Yojana (SSY) Calculator to determine the maturity amount of your SSY investment after 14 years. Provide Investment Amount, Returns, and Age of Girl Child to determine Total Investment and Total Interest Earned.";
      keywords =
        keywords + "Sukanya Samriddhi Yojana Calculator, SSY Calculator, SSY";
    }
    if (path.match("swp-calculator") !== null) {
      name = "Systematic Withdrawal Plan Calculator";
      description =
        "Use the Systematic Withdrawal Plan (SWP) Calculator to determine the monthly withdrawals from your investments and the total value of your investment at the end of the SWP.";
      keywords =
        keywords + "Systematic Withdrawal Plan Calculator, SWP Calculator, SWP";
    }
    if (path.match("ppf-calculator") !== null) {
      name =
        "Public Provident Fund (PPF) Calculator - Calculate PPF returns online with Piggy";
      description =
        "Public Provident Fund (PPF) Calculator : Calculate your Public Provident Fund Interest / returns with Piggy Online PPF Calculator. Know about PPF benefits and how it helps you in saving taxes.";
      keywords =
        keywords + "Public Provident Fund Calculator, PPF Calculator, PPF";
    }
    if (path.match("nsc-calculator") !== null) {
      name = "National Savings Certificate (NSC) Calculator";
      description =
        "Use Piggy online NSC calculator to calculate the return on investment / current value of your National Savings Certificates (NSC).";
      keywords =
        keywords +
        "National Savings Certificate Calculator, NSC Calculator, NSC";
    }
  } else if (path.match("s/switch") !== null) {
    name =
      "Switch Regular To Direct Funds - Save Hidden Losses in Mutual Funds";
    description =
      "Your Mutual Fund portfolio will be worth 34% more in 20 years if you stop paying 1.5% in annual commissions today. Use piggy to switch your regular plans to direct plans.";
    keywords = keywords + "Switch, Direct, Regular Fund, Save Commissions";
  } else if (path.match("s/sip") !== null) {
    name =
      "Invest in SIPs from Piggy App - Start your money saving habit in very simple way.";
    description =
      "Grow your wealth with Systematic Investment Plan(SIP) in a very simple way. Start SIP as low as Rs 100 on Piggy and watch your small investment turn into a sizeable sum in a few years.";
  } else if (path.match("disclaimer") !== null) {
    name = "Disclaimer | Piggy - Free Direct Funds Investment Platform";
    description =
      "Any sort of graphical representations, recommendations, feedback and reviews, provided on the Site, are in no way, either a guarantee for the performance of the funds or an assessment of the fund’s, or the fund’s underlying securities’ creditworthiness.";
  } else if (path.match("risk") !== null) {
    name = "Risk | Piggy - Free Direct Mutual Funds Investment Platform";
    description =
      "Mutual fund investments are subject to market risks. Please read the scheme information and other related documents carefully before investing";
  } else if (path.match("contact") !== null) {
    name = "Contact Us | Piggy - Free Investment Platform";
    description =
      "Reach to Piggy Team for any feedback or suggestion. In case of any issue mail us at support@piggy.co.in";
  } else if (path.match("privacy") !== null) {
    name = "Privacy Policy | Piggy - Mutual Funds Investment App";
    description =
      "Data and Privacy Policy of Piggy sets the policies for the use of platform and protects any information that can be shared while using the platform.";
  } else if (path.match("legal") !== null) {
    name = "Terms of Use | Piggy - Free Investment Platform";
    description =
      "Terms and Conditions of Using Piggy Investment Platform. By accepting, you are agreeing to comply with and be bound by the terms and conditions of use.";
  }

  return { name, description, keywords };
}

export function commonSeoData(path) {
  const data = getSeoParams(path);
  let schema = [];

  let webPageSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    inLanguage: "en-US",
    url: `https://www.piggy.co.in${path}`,
    mainEntity: {
      "@type": "WebPage",
      author: {
        "@type": "Organization",
        name: "Piggy"
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://www.piggy.co.in${path}`
      },
      headline: `${data.name}`,
      description: `${data.description}`,
      keywords: `${data.keywords}`,
      publisher: {
        "@type": "Organization",
        name: "Piggy",
        logo: {
          "@type": "ImageObject",
          url:
            "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/logo-large.png",
          width: 140,
          height: 33
        }
      },
      potentialAction: {
        "@type": "SearchAction",
        target: "https://www.piggy.co.in/w/mutual-funds?q={search_term_string}",
        "query-input": "required name=search_term_string"
      },

      image: {
        "@type": "ImageObject",
        url:
          "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/logo-large.png"
      }
    }
  };

  const organizationSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Piggy",
    legalName: "Piggy Investment Platform",
    url: "https://www.piggy.co.in",
    logo:
      "https://s3-ap-southeast-1.amazonaws.com/piggy-public/assets/images/piggy/logo-large.png",
    email: "support@piggy.co.in",
    foundingDate: "2016",
    foundingLocation: "Maharashtra, India",
    founders: [
      {
        "@type": "Person",
        name: "Ankush Singh",
        gender: "Male",
        nationality: "India",
        sameAs: ["https://www.linkedin.com/in/ankushsinghbaedas/"]
      },
      {
        "@type": "Person",
        name: "Kunal Sangwan",
        gender: "Male",
        nationality: "India",
        sameAs: ["https://www.linkedin.com/in/nuhbye/"]
      },
      {
        "@type": "Person",
        name: "Nikhil Mantha",
        gender: "Male",
        nationality: "India",
        sameAs: ["https://www.linkedin.com/in/nikhil-mantha-1b14241b/"]
      }
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91-22-62364821",
        email: "support@piggy.co.in",
        contactType: "Customer Service",
        contactOption: "TollFree"
      }
    ],
    address: {
      "@type": "PostalAddress",
      addressLocality: "IndiraNagar, Bengaluru",
      postalCode: "560008",
      streetAddress:
        "PiggyHQ, 3rd Floor Atlantic Center, IndiraNagar 100ft road"
    },
    sameAs: [
      "https://www.facebook.com/savewithpiggy",
      "https://twitter.com/savewithpiggy/",
      "https://www.linkedin.com/company/savewithpiggy/",
      "https://www.instagram.com/savewithpiggy/"
    ]
  };

  schema.push(webPageSchema);
  schema.push(organizationSchema);

  return schema;
}

export function pageSchema(pageLink) {
  let schema = null;

  if (pageLink.match("sukanya-samriddhi-yojana-calculator"))
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is the account limit under the Sukanya Samriddhi Yojana?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Only one Sukanya Samriddhi Yojana account can be opened in the name of a girl child. Maximum 3 accounts can be opened if you have triplet daughters"
          }
        },
        {
          "@type": "Question",
          name: "Can I take a loan against the balance in the SSY account?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "No, you cannot take loan against SSY account balance"
          }
        },
        {
          "@type": "Question",
          name:
            "Is premature closure of the Sukanya Samriddhi Yojana Account allowed?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Yes, it is allowed in certain cases like on the grounds of terminal illness or death of primary account holder."
          }
        },
        {
          "@type": "Question",
          name:
            "What is the penalty if I miss my SSY account minimum annual payment?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "A penalty of Rs 50 is applied if minimum amount of Rs. 250 is not deposited in the account during a financial year."
          }
        },
        {
          "@type": "Question",
          name: "What is the maturity or termination period of the SSY?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "The Sukanya Samridhi Yojana account matures after 21 years from the date of opening of the account"
          }
        },
        {
          "@type": "Question",
          name:
            "Are there any tax benefits in case an individual invests in an SSY account?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Yes, you can take tax benefits upto Rs 1.5 lakhs Under Section 80C of the Income Tax Act"
          }
        },
        {
          "@type": "Question",
          name: "Can the same girl child have more than one SSY account?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "No, a girl child can have only one SSY account under her name"
          }
        }
      ]
    };

  if (pageLink.match("nsc-calculator"))
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "How is NSC interest calculated?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "The interest paid on an NSC is compounded half-yearly. You can claim tax exemptions on annual interest earned on NSC"
          }
        },
        {
          "@type": "Question",
          name: "Can I purchase NSC online?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Yes, if you have savings account with bank/Post office through e-mode"
          }
        },
        {
          "@type": "Question",
          name: "Is NSC maturity amount is taxable?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "You can tax rebates up to Rs 1.5lakh under section 80c on the Income-tax act. Furthermore, interest earned also qualifies for tax rebates"
          }
        },
        {
          "@type": "Question",
          name: "Which is better NSC or PPF?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Both NSC and PPF offer attractive interest rate. Deciding between either should be based on the requirement of the individual"
          }
        },
        {
          "@type": "Question",
          name: "Is NSC safe?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "The National Savings Certificate is backed by the government, and hence, it is one of the safest investment option available"
          }
        }
      ]
    };

  if (pageLink.match("step-up-sip-calculator"))
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is step up in mutual fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Increasing SIP (Systematic Investment Plan) value by a certain amount after a specific period ( weekly/monthly/quaterly) is step up SIP in mutual fund"
          }
        },
        {
          "@type": "Question",
          name: "How to do a Step-up SIP?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "You can do Step-up SIP through https://www.piggy.co.in or Piggy Mutual Funds App for free"
          }
        },
        {
          "@type": "Question",
          name: " Difference between Step-up SIPs and  Conventional SIPs?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Unlike Step-up SIP, Conventional SIP do not give investors opportunity to increase the value of their SIP during investment tenure"
          }
        }
      ]
    };

  if (pageLink.match("sbi-mutual-fund"))
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Is SBI Mutual Fund good?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "SBI Mutual fund has third largest Assets under management (AUM) of Rs 2.83 trillion. It is good as it provides capital appreciation at a reduced risk "
          }
        },
        {
          "@type": "Question",
          name: "Is SBI Mutual Fund is safe?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Your money is managed by professional managers and invested in reputed companies. It is safe to invest in SBI mutual fund or any other AMC for that matter "
          }
        },
        {
          "@type": "Question",
          name: "How can I buy SBI Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "You can buy SBI Mutual Fund online without any paperwork for free using Piggy"
          }
        },
        {
          "@type": "Question",
          name: "Is SBI SIP Mutual fund tax free?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Investment in SBI Magnum Taxgain Scheme, an ELSS scheme of SBI Mutual fund, is tax free. Rest are not"
          }
        },
        {
          "@type": "Question",
          name: "How do I complete KYC for investment in SBI Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Complete KYC through Piggy, a SEBI registered investment\tadvisor, Submit Proof of identity and Proof of address to complete KYC"
          }
        }
      ]
    };

  if (pageLink.match("hdfc-mutual-fund"))
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Is it good to invest in HDFC Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "HDFC Mutual fund ranks first largest Assets under management (AUM) of Rs 3.62 trillion. The key to a successful investment is discipline"
          }
        },
        {
          "@type": "Question",
          name: "Is HDFC Mutual Fund is safe?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Your money is managed by professional managers and invested in reputed companies. It is safe to invest in HDFC mutual fund or any other AMC for that matter "
          }
        },
        {
          "@type": "Question",
          name: "How can I buy HDFC Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "You can buy HDFC Mutual Fund online without any paperwork for free using Piggy"
          }
        },
        {
          "@type": "Question",
          name: "Is HDFC SIP Mutual fund tax free?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Investment in HDFC Long Term Advantage Fund, an ELSS scheme of HDFC Mutual fund, is tax free"
          }
        },
        {
          "@type": "Question",
          name: "How do I complete KYC for investment in HDFC Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Complete KYC through Piggy, a SEBI registered investment\tadvisor, Submit Proof of identity and Proof of address to complete KYC"
          }
        }
      ]
    };

  if (pageLink.match("mutual-funds/amc/sundaram-mutual-fund")) {
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Which are top 3 Sundaram Mutual Funds to invest ?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "1. Sundaram Select Focus - Direct Plan\n2. Sundaram Equity Hybrid Fund - Direct Plan\n3. Sundaram Infrastructure Advantage Fund Direct"
          }
        },
        {
          "@type": "Question",
          name:
            "What are the different types of mutual fund schemes Sundaram offers ?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "It offers a wide range of schemes, starting from equity, debt, income and liquid funds. "
          }
        },
        {
          "@type": "Question",
          name: "What is a Systematic Investment Plan (SIP)?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "SIP or Systematic Investment Plan refers to an investment strategy that allows investors to invest a fixed amount of money at regular intervals in a mutual fund scheme. "
          }
        }
      ]
    };
  }

  if (pageLink.match("mutual-funds/amc/uti-mutual-fund")) {
    schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "How good is UTI mutual fund ?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Over the years the equity Funds by UTI Mutual Fund has given the substantial returns to the investors . So to create a long term wealth Uti mutual funds are the best option. "
          }
        },
        {
          "@type": "Question",
          name: "What is full form of UTI Mutual Fund?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "UTI Mutual Fund stands for Unit Trust of India as a SEBI registered mutual fund from 1st February 2003."
          }
        },
        {
          "@type": "Question",
          name: "What is the Exit load of UTI Mutual fund ?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "If the units are sold within one year of buying typically, equity mutual fund schemes levy an exit load of 1%. So you should check the exit load of the fund before investing. "
          }
        }
      ]
    };
  }

  return schema;
}
